import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

import { BACK_END } from './utils/constants'

const client = new ApolloClient({
  link: createUploadLink({
    uri: `${BACK_END}/graphql`,
    credentials: 'include'
  }),
  cache: new InMemoryCache({
    addTypename: false
  })
})

export default client
