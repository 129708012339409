import { createContext, useEffect, useMemo, useState, useContext } from 'react'

import { GET_EDITORIAL_CONFIG } from '../graphql/editorialConfig/queries'
import { currencyAdornment } from '../utils'
import { apiRequest, graphqlRequest } from '../utils/api'
import { Currencies } from '../utils/types'

const ConfigContext = createContext(null)

export const useConfigContext = () => useContext(ConfigContext)

interface ConvertOptions {
  main?: boolean
  adornment?: boolean
  format?: boolean
  adornmentEnd?: boolean
  decimal?: boolean
  currency?: Currencies
}

export function ConfigProvider(props) {
  const [config, setConfig] = useState<any>({})
  const [editorialConfig, setEditorialConfig] = useState<any>({})
  const [isMuted, setIsMuted] = useState(true)
  const [currency, setCurrency] = useState(Currencies.USD)
  const [priceUsd, setPriceUsd] = useState(0)
  const [priceEur, setPriceEur] = useState(0)

  async function getConfig() {
    try {
      const { data } = await apiRequest({
        method: 'get',
        path: `/config`
      })
      const config = data
      if (!config) return null

      setConfig(config)
      setPriceUsd(config?.pkn?.usd || 0)
      setPriceEur(config?.pkn?.eur || 0)

      setTimeout(() => getConfig(), 60000)
    } catch (error) {}
  }

  async function getEditorialConfig() {
    try {
      const { editConfig } = await graphqlRequest({
        query: GET_EDITORIAL_CONFIG
      })
      const config = editConfig
      if (!config) return null

      setEditorialConfig(config)
    } catch (error) {}
  }

  useEffect(() => {
    async function init() {
      await getConfig()
      await getEditorialConfig()
    }

    init()
  }, [])

  const convertPrice = (value = 0, options: ConvertOptions = {}) => {
    const { main, adornment, adornmentEnd, format, decimal, currency: valueCurrency = Currencies.USD } = options

    if (valueCurrency === Currencies.EUR) return 0

    let amount = 0

    if (currency === Currencies.USD) {
      if (main && valueCurrency === Currencies.PKN) amount = priceUsd ? value * priceUsd : 0
      else if ((main && valueCurrency === Currencies.USD) || valueCurrency === Currencies.PKN) amount = value
      else amount = priceUsd ? value / priceUsd : 0
    } else {
      if (main && valueCurrency === Currencies.USD) amount = priceUsd ? value / priceUsd : 0
      else if ((main && valueCurrency === Currencies.PKN) || valueCurrency === Currencies.USD) amount = value
      else amount = priceUsd ? value * priceUsd : 0
    }

    const finalAmount = decimal && amount % 1 ? amount : Math.round(amount)
    const formattedAmount = format
      ? new Intl.NumberFormat('en-US', {
          minimumFractionDigits: decimal && amount % 1 ? 2 : 0,
          maximumFractionDigits: decimal && amount % 1 ? 2 : 0
        })
          .format(finalAmount)
          .replace(/,/g, ' ')
      : finalAmount

    const symbol =
      currency === Currencies.USD
        ? main
          ? currencyAdornment[Currencies.USD]
          : currencyAdornment[Currencies.PKN]
        : main
        ? currencyAdornment[Currencies.PKN]
        : currencyAdornment[Currencies.USD]

    return adornment
      ? adornmentEnd
        ? `${formattedAmount} ${symbol} `
        : `${symbol} ${formattedAmount}`
      : formattedAmount
  }

  const convertPknAmount = (pkn, asNum, round = true) => {
    if (!pkn) return 0
    const amount = config?.pkn?.usd ? pkn * config.pkn.usd : 0

    if (asNum) return amount < 1 ? amount : round ? Math.round(amount) : amount || 0

    return new Intl.NumberFormat('en-US', {})
      .format(amount < 1 ? amount : round ? Math.round(amount) : amount || 0)
      .replace(/,/g, ' ')
  }

  const convertUsdAmount = (usd, asNum, round = true) => {
    if (!usd) return 0
    const amount = config.pkn.usd ? usd / config.pkn.usd : 0

    if (asNum) return amount < 1 ? amount : round ? Math.round(amount) : amount || 0

    return new Intl.NumberFormat('en-US', {})
      .format(amount < 1 ? amount : round ? Math.round(amount) : amount || 0)
      .replace(/,/g, ' ')
  }

  const values = useMemo(
    () => ({
      config,
      editorialConfig,
      isMuted,
      currency,
      setIsMuted,
      convertPrice,
      convertPknAmount,
      convertUsdAmount,
      setCurrency
    }),
    [config, editorialConfig, isMuted, currency, convertPrice]
  )

  return <ConfigContext.Provider {...props} value={values} />
}
