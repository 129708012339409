import { gql } from '@apollo/client'

export const SEARCH_USER = gql`
  query searchUser(
    $searchInput: SearchInput!
    $filter: FilterUserInput
    $conn: ConnectionInput
    $order: SearchOrderInput
  ) {
    searchUser(searchInput: $searchInput, filter: $filter, conn: $conn, order: $order) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      items {
        id
        name
        username
        wallet
        cloud_wallet
        description
        profile_picture
        certified
        lastNFPs: createdNfts(conn: { first: 1 }) {
          items {
            id
            file_compressed
            file_thumbnail
            image
          }
        }
      }
    }
  }
`

export const SEARCH_NFT = gql`
  query searchNft(
    $searchInput: SearchInput!
    $filter: FilterNftInput
    $conn: ConnectionInput
    $order: SearchOrderInput
  ) {
    searchNft(searchInput: $searchInput, filter: $filter, conn: $conn, order: $order) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      items {
        id
        name
        file
        file_compressed
        file_thumbnail
        image
        animation_url
        is_for_sale
        subType
        creator {
          id
          name
          username
          wallet
          cloud_wallet
          certified
        }
        owner {
          id
          name
          username
          wallet
          cloud_wallet
          certified
        }
      }
    }
  }
`

export const SEARCH_CATEGORIES = gql`
  query searchCategories($searchInput: SearchInput!) {
    searchCategories(searchInput: $searchInput) {
      id
      name
    }
  }
`
