import { createContext, useMemo, useState, useContext } from 'react'
import axios from 'axios'

import { apiRequest } from './api'

const UploadContext = createContext(null)

export const useUploadContext = () => useContext(UploadContext)

const FILE_CHUNK_SIZE = 100_000_000

export function UploadProvider(props) {
  const [uploading, setUploading] = useState(false)
  const [uploadingProgress, setUploadingProgress] = useState(0)

  const upload = async ({ type, file }) => {
    setUploadingProgress(0)
    setUploading(true)

    const { data } = await apiRequest({
      method: 'post',
      path: `/upload/create`,
      body: { type, file: file.name, parts: Math.ceil(file.size / FILE_CHUNK_SIZE) }
    })

    const { bucket, key, upload_id, urls = [] } = data
    if (!bucket || !key || !upload_id || !urls.length) {
      throw new Error('Unable to upload content')
    }

    const results = []

    for (let i = 0; i < urls.length; i++) {
      const start = i * FILE_CHUNK_SIZE
      const end = (i + 1) * FILE_CHUNK_SIZE
      const blob = i < urls.length ? file.slice(start, end) : file.slice(start)

      setUploadingProgress(Math.round(i * (100 / urls.length)))

      const result = await axios({ method: 'PUT', url: urls[i], data: blob })

      results.push({ ETag: result.headers.etag, PartNumber: i + 1 })
    }

    await apiRequest({
      method: 'post',
      path: `/upload/complete`,
      body: { ...data, parts: results }
    })

    setUploadingProgress(100)

    setUploading(false)
    setUploadingProgress(0)

    return key
  }

  const values = useMemo(
    () => ({
      upload,
      uploading,
      uploadingProgress
    }),
    [uploading, uploadingProgress]
  )

  return <UploadContext.Provider {...props} value={values} />
}
