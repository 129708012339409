import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/router'
import React from 'react'

import { ConnectionInput } from '../graphql'
import { SEARCH_CATEGORIES, SEARCH_NFT, SEARCH_USER } from '../graphql/search/queries'

import { useAPIContext } from './api'
import { useLocalStorage } from './localStorage'

import { useDebounce } from '.'

const SearchContext = React.createContext(null)

export const useSearchContext = () => React.useContext(SearchContext)

export function SearchProvider(props) {
  const { graphqlRequest } = useAPIContext()
  const router = useRouter()

  const { s } = router.query

  const [searchHistory, setSearchHistory] = useLocalStorage('rs_pokmi', [])

  const [searchValue, setSearchValue] = React.useState(s || '')
  const [creators, setCreators] = React.useState({} as any)
  const [users, setUsers] = React.useState({} as any)
  const [nfts, setNfts] = React.useState({} as any)
  const [pokAndPlays, setPokAndPlays] = React.useState({} as any)
  const [categories, setCategories] = React.useState({} as any)
  const [isSearching, setIsSearching] = React.useState(false)

  const debouncedSearchValue = useDebounce(searchValue, 250)

  React.useEffect(() => {
    if (s) setSearchValue(s)
  }, [s])

  React.useEffect(() => {
    setIsSearching(searchValue !== debouncedSearchValue)
  }, [searchValue, debouncedSearchValue])

  React.useEffect(() => {
    async function searchDeboucedValue() {
      setIsSearching(true)
      await getData(debouncedSearchValue || '', { first: 4 })
      setIsSearching(false)
    }
    searchDeboucedValue()
  }, [debouncedSearchValue])

  const getData = async (value, conn: ConnectionInput = {}) => {
    await Promise.all([
      getCreators(value, conn),
      getUsers(value, conn),
      getNfts(value, conn),
      getPokAndPlays(value, conn),
      getCategories(value)
    ])
  }

  const getCreators = React.useCallback(
    async (value, conn: ConnectionInput = {}) => {
      try {
        const data = await graphqlRequest(
          {
            query: SEARCH_USER,
            variables: {
              searchInput: {
                query: value || '',
                path: ['id', 'name', 'username', 'wallet', 'cloud_wallet', 'email']
              },
              conn: { ...conn, after: conn.after || '' },
              filter: { is_creator: true },
              order: { score: 'DESC' }
            }
          },
          { fetchPolicy: '' }
        )

        const hits = (creators.query === value && creators.hits) || []

        data?.searchUser?.items.forEach((hit) => {
          if (!hits.find(({ id }) => hit.id === id)) hits.push(hit)
        })

        setCreators({
          ...data,
          hits,
          query: value,
          nbHits: data?.searchUser?.totalCount,
          cursor: data?.searchUser?.pageInfo?.endCursor
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    },
    [creators]
  )

  const getUsers = React.useCallback(
    async (value, conn: ConnectionInput = {}) => {
      try {
        if (!value) {
          return setUsers({ ...users, hits: [], query: '', nbHits: 0, cursor: '' })
        }
        const data = await graphqlRequest(
          {
            query: SEARCH_USER,
            variables: {
              searchInput: {
                query: value || '',
                path: ['id', 'name', 'username', 'wallet', 'cloud_wallet', 'email']
              },
              conn: { ...conn, after: conn.after || '' },
              filter: { is_creator_ne: true },
              order: value ? { score: 'DESC' } : { created_at: 'DESC' }
            }
          },
          { fetchPolicy: '' }
        )

        const hits = (users.query === value && users.hits) || []

        data?.searchUser?.items.forEach((hit) => {
          if (!hits.find(({ id }) => hit.id === id)) hits.push(hit)
        })

        setUsers({
          ...data,
          hits,
          query: value,
          nbHits: data?.searchUser?.totalCount,
          cursor: data?.searchUser?.pageInfo?.endCursor
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    },
    [users]
  )

  const getNfts = React.useCallback(
    async (value, conn: ConnectionInput = {}) => {
      try {
        const data = await graphqlRequest(
          {
            query: SEARCH_NFT,
            variables: {
              searchInput: {
                query: value || '',
                path: ['id', 'name']
              },
              conn: { ...conn, after: conn.after || '' },
              filter: {},
              order: { created_at: 'DESC' }
            }
          },
          { fetchPolicy: '' }
        )

        const hits = (nfts.query === value && nfts.hits) || []

        data?.searchNft?.items.forEach((hit) => {
          if (!hits.find(({ id }) => hit.id === id)) hits.push(hit)
        })

        setNfts({
          ...data,
          hits,
          query: value,
          nbHits: data?.searchNft?.totalCount,
          cursor: data?.searchNft?.pageInfo?.endCursor
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    },
    [nfts]
  )

  const getPokAndPlays = React.useCallback(
    async (value, conn: ConnectionInput = {}) => {
      try {
        const data = await graphqlRequest(
          {
            query: SEARCH_NFT,
            variables: {
              searchInput: {
                query: value || '',
                path: ['id', 'name']
              },
              conn: { ...conn, after: conn.after || '' },
              filter: { pokAndPlay: true },
              order: { created_at: 'DESC' }
            }
          },
          { fetchPolicy: '' }
        )

        const hits = (pokAndPlays.query === value && pokAndPlays.hits) || []

        data?.searchNft?.items.forEach((hit) => {
          if (!hits.find(({ id }) => hit.id === id)) hits.push(hit)
        })

        setPokAndPlays({
          ...data,
          hits,
          query: value,
          nbHits: data?.searchNft?.totalCount,
          cursor: data?.searchNft?.pageInfo?.endCursor
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    },
    [pokAndPlays]
  )

  const getCategories = React.useCallback(
    async (value) => {
      try {
        const data = await graphqlRequest(
          {
            query: SEARCH_CATEGORIES,
            variables: {
              searchInput: {
                query: value || ''
              }
            }
          },
          { fetchPolicy: '' }
        )

        const hits = (categories.query === value && categories.hits) || []

        data?.searchCategories?.forEach((hit) => {
          if (!hits.find(({ id }) => hit.id === id)) hits.push(hit)
        })

        setCategories({
          ...data,
          hits,
          query: value,
          nbHits: data?.searchCategories?.length
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    },
    [categories]
  )

  const values = React.useMemo(
    () => ({
      isSearching,
      searchValue,
      searchHistory,
      creators,
      users,
      nfts,
      pokAndPlays,
      categories,
      setSearchValue,
      setSearchHistory,
      getData,
      getCreators,
      getUsers,
      getNfts,
      getPokAndPlays,
      getCategories
    }),
    [
      isSearching,
      searchValue,
      searchHistory,
      creators,
      users,
      nfts,
      pokAndPlays,
      categories,
      setSearchValue,
      getData,
      getCreators,
      getUsers,
      getNfts,
      getPokAndPlays,
      getCategories
    ]
  )

  return <SearchContext.Provider {...props} value={values} />
}
