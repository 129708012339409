import axios from 'axios'
import { useEffect, useState } from 'react'

import { BACK_END } from '../utils/constants'

export async function apiRequest(options: any = {}) {
  const { method = 'get', path, body } = options

  const result = await axios({
    method,
    url: `${BACK_END}${path}`,
    data: body,
    withCredentials: true
  })

  return result
}

export function useApiRequest(options, deps) {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    apiRequest(options)
      .then((res) => {
        setData(res)
        setError(null)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        setError(err)
      })
  }, deps)

  return {
    isLoading,
    data,
    error
  }
}
