import axios from 'axios'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/nextjs'

import apolloClient from '../apolloClient'

import { BACK_END } from './constants'

export async function apiRequest(options: any = {}) {
  const { method = 'get', path, body, userToken } = options

  const headers = {}

  if (userToken) headers['x-access-token'] = userToken

  const result = await axios({
    method,
    headers,
    url: `${BACK_END}${path}`,
    data: body,
    withCredentials: true
  })

  return result
}

export async function graphqlRequest(options: any = {}) {
  const headers = {}

  const { data } = await apolloClient.query({
    query: options.query,
    variables: options.variables,
    context: { headers },
    fetchPolicy: options.fetchPolicy || 'no-cache'
  })

  return data
}

export function useApiRequest(options, deps) {
  const { apiRequest } = useAPIContext()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    apiRequest(options)
      .then((res) => {
        setData(res)
        setError(null)
        setIsLoading(false)
      })
      .catch((err) => {
        Sentry.captureException(err)
        setIsLoading(false)
        setError(err)
      })
  }, deps)

  return {
    isLoading,
    data,
    error
  }
}

const APIContext = createContext(null)
export const useAPIContext = () => useContext(APIContext)

export function APIProvider(props) {
  async function apiRequest(options) {
    const { method = 'get', path, body } = options

    const headers = {}

    const result = await axios({
      method,
      headers,
      url: `${BACK_END}${path}`,
      data: body,
      withCredentials: true
    })

    return result
  }

  async function graphqlRequest(options) {
    const headers = {}

    const { data } = await apolloClient.query({
      query: options.query,
      variables: options.variables,
      context: { headers },
      fetchPolicy: 'no-cache'
    })

    return data
  }

  const values = useMemo(
    () => ({
      apiRequest,
      graphqlRequest
    }),
    []
  )

  return <APIContext.Provider {...props} value={values} />
}

export const getVideoUrl = ({ file_playlist, id }) => {
  const playlistName = file_playlist.includes('playlist') ? 'playlist' : 'video_'
  return `${BACK_END}/watch/admin/${id}/${playlistName}.m3u8`
}
