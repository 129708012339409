import Login from '../components/Login'

import { useUserContext } from './user'

export const ProtectRoute = ({ children }) => {
  const { user } = useUserContext()
  if (!user) {
    return <Login />
  }
  return children
}
