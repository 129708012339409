import Link from 'next/link'
import { BanIcon } from '@heroicons/react/outline'
import { Button } from '@thepokencompany/ui'

import { BACK_END } from '../utils/constants'

export default function Login() {
  const redirect = typeof window !== 'undefined' && encodeURIComponent(window.location.href)

  return (
    <div className='flex flex-col items-center w-full text-white'>
      <div className='max-w-screen-md h-screen flex flex-row w-full justify-center items-center text-center'>
        <div className='py-24 flex flex-col justify-center items-center'>
          <BanIcon className='w-20 h-20' />
          <p className='my-2 text-gtxt2 text-3xl'>Forbidden</p>
          <p className='my-2 text-3xl'>You must be logged to access this platform</p>
          <Link href={`${BACK_END}/google/login?redirect=${redirect}`}>
            <Button className='my-2'>Login</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}
