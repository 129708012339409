import { useEffect, useState, createContext, useContext, useMemo } from 'react'

import { apiRequest } from '../hooks/api'

const UserContext = createContext(null)

export const useUserContext = () => useContext(UserContext)

export function UserProvider(props) {
  const [user, setUser] = useState(null)

  useEffect(() => {
    async function init() {
      try {
        const result = await apiRequest({ path: `/logged` })
        setUser(result?.data)
      } catch (error) {
        setUser(null)
      }
    }
    init()
  }, [])

  const logout = async () => {
    try {
      await apiRequest({ path: `/logout` })
    } catch (error) {}
    setUser(null)
  }

  const isActionAllowed = (action) => {
    const actions = Array.isArray(action) ? action : [action]
    return !!(user.admin || actions.some((act) => user[act]))
  }

  const values = useMemo(
    () => ({
      user,
      logout,
      isActionAllowed
    }),
    [user]
  )

  return <UserContext.Provider {...props} value={values} />
}
