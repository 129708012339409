import { useState, createContext, useContext, useMemo } from 'react'

const ModalContext = createContext(null)
export const useModalContext = () => useContext(ModalContext)

const CLOSED_STATE = {
  open: false,
  preventClose: false,
  onClose: () => {},
  content: null
}

export function ModalProvider(props) {
  const [modalProps, setModalProps] = useState([])

  const openModal = ({ content, onClose, preventClose }) => {
    setModalProps(
      modalProps.concat({
        content,
        onClose,
        open: true,
        preventClose: !!preventClose
      })
    )
    document.body.style['overflow-y'] = 'hidden'
    document.body.style['touch-action'] = 'none'
  }

  const closeModal = () => {
    const modal = modalProps[modalProps.length - 1]
    if (modal?.onClose) modal.onClose()
    setModalProps(modalProps.slice(0, -1))

    document.body.style['overflow-y'] = 'scroll'
    document.body.style['touch-action'] = 'auto'
  }

  const values = useMemo(() => {
    const current = modalProps[modalProps.length - 1] || CLOSED_STATE
    return {
      openModal,
      closeModal,
      modalProps: [],
      content: current.content,
      onClose: current.onClose,
      open: current.open,
      preventClose: current.preventClose
    }
  }, [openModal, closeModal])

  return <ModalContext.Provider {...props} value={values} />
}
