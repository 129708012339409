import { gql } from '@apollo/client'

export const GET_EDITORIAL_CONFIG = gql`
  query editConfig {
    editConfig {
      id
      accounts {
        items {
          id
          name
          username
          profile_picture
          certified
          wallet
          cloud_wallet
          subscribersCount
          likers
          createdNfts(conn: {}) {
            totalCount
          }
          followers {
            totalCount
          }
        }
      }
    }
  }
`
