export const IS_PROD = process.env.NEXT_PUBLIC_ENV === 'production'

export const BACK_END = process.env.NEXT_PUBLIC_BACK_END || 'http://localhost:4000'
export const FRONT_END = process.env.NEXT_PUBLIC_APP_URL || 'http://localhost:3000'

export enum StatusBulkNft {
  pending = 'pending',
  uploaded = 'uploaded',
  uploaded_missing_file = 'uploaded_missing_file',
  uploaded_missing_unlockable = 'uploaded_missing_unlockable',
  created = 'created',
  creation_fail = 'creation_fail',
  minted = 'minted',
  mint_fail = 'mint_fail'
}

export const CHAIN_SCAN_HOST = {
  ERC: !IS_PROD ? 'https://rinkeby.etherscan.io' : 'https://etherscan.io',
  BSC: !IS_PROD ? 'https://testnet.bscscan.com' : 'https://bscscan.com'
}

export enum ConvertStatus {
  pending = 'pending',
  complete = 'complete',
  inProgress = 'inProgress',
  failedResolution = 'failedResolution',
  failed = 'failed'
}

export const allocationAddress = IS_PROD
  ? '0xeFA10854FdE2baF92f038f1c62530725E4F2c1A8'
  : '0x94427F3F2d1836A0a37379Df5187ccBfF797C447'

export const ERC_PROVIDER = IS_PROD ? 'homestead' : 'rinkeby'

export const ETHERSCAN_API_KEY = IS_PROD ? 'HD4JU37GXZ14UPFUIFGQ74IGW8XPRWP5CW' : 'UKS1UCIZSZUS6HMW5DBUEQ5QPU6TAH8WBY'

export const BSCSCAN_API_KEY = IS_PROD ? 'FJP1UGFHQ59IWMTP11MB7ZAQEXERXQJBTK' : '9KK7MCGQI5V5IBP8AHXPQZXEZ2YJKHE475'

const minPrice = parseInt(process.env.NEXT_PUBLIC_MIN_PRICE || '3', 10)
export const MIN_PRICE = isNaN(minPrice) ? 3 : minPrice

const minPricePkn = parseInt(process.env.NEXT_PUBLIC_MIN_PRICE_BUY_PKN || '10', 10)
export const MIN_PRICE_BUY_PKN = isNaN(minPricePkn) ? 10 : minPricePkn

const maxPricePkn = parseInt(process.env.NEXT_PUBLIC_MAX_PRICE_BUY_PKN || '1000', 10)
export const MAX_PRICE_BUY_PKN = isNaN(maxPricePkn) ? 100 : maxPricePkn
