import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

import { IS_PROD } from './constants'
import { Currencies } from './types'

export function escapeHtml(unsafe: string): string {
  if (typeof unsafe !== 'string') return unsafe
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

export function truncateWallet(wallet: string, long?: number) {
  if (!wallet) return ''

  return `${wallet.substring(0, long || 5)}...${wallet.substring(wallet.length - (long - 1 || 4), wallet.length)}`
}

export const isValidUsername = (username: string): boolean => {
  return /^[0-9a-z.]+$/.test(username)
}

export function getKycUrl(id) {
  return IS_PROD ? `https://rareporn.getid.ee/detail-view/${id}` : `https://rareporn.getid.ee/detail-view/${id}`
}

export function getKycSearchUrl(id) {
  return IS_PROD ? `https://rareporn.getid.ee/?searchString=${id}` : `https://rareporn.getid.ee/?searchString=${id}`
}

export function isVideo(url) {
  if (!url) return false
  try {
    const contentUrl = new URL(url)
    const pathname = contentUrl.pathname
    return (
      url.toLowerCase().endsWith('mp4') ||
      pathname.toLowerCase().endsWith('mp4') ||
      url.toLowerCase().endsWith('mov') ||
      pathname.toLowerCase().endsWith('mov') ||
      url.toLowerCase().endsWith('m3u8') ||
      pathname.toLowerCase().endsWith('m3u8')
    )
  } catch (error) {
    return url.toLowerCase().endsWith('mp4') || url.toLowerCase().endsWith('mov') || url.toLowerCase().endsWith('m3u8')
  }
}

export function formatPokenAmount(amount: number) {
  if (typeof amount !== 'number') return 'N/A'
  return new Intl.NumberFormat('en-US', {}).format(amount < 1 ? amount : Math.floor(amount) || 0).replace(/,/g, ' ')
}

export function hexToBase64(str) {
  let bString = ''
  for (let i = 0; i < str.length; i += 2) {
    bString += String.fromCharCode(parseInt(str.substr(i, 2), 16))
  }
  return btoa(bString)
}

export function useQueryString(key: string, initialState?: string): [string | undefined, Function] {
  const router = useRouter()

  function getValue(): string | undefined {
    const qsValue = router.query[key]
    if (!qsValue) return initialState
    return decodeURIComponent(Array.isArray(qsValue) ? qsValue[0] : qsValue)
  }

  function setValue(value?: string) {
    router.replace({
      pathname: router.pathname,
      query: {
        ...router.query,
        [key]: encodeURIComponent(value)
      }
    })
  }

  return [getValue(), setValue]
}

export const removeNull = (object) => {
  Object.keys(object).forEach((k) => object[k] == null && delete object[k])
  return object
}

// Format duration in seconds to hh:mm:ss
export const secondsToTime = (duration) => {
  const hours = Math.floor(duration / 3600)
      .toString()
      .padStart(2, '0'),
    minutes = Math.floor((duration % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    seconds = Math.floor(duration % 60)
      .toString()
      .padStart(2, '0')

  return `${hours}:${minutes}:${seconds}`
}

export const useDebounce = (value, delay?) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value])

  return debouncedValue
}

export const randomIntFromInterval = (min = 100, max = 10000) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function formatDuration(duration: number) {
  duration = Math.round(duration)
  const seconds = duration % 60
  const minutes = ((duration - seconds) / 60) % 60
  const hours = (duration - minutes * 60 - seconds) / 3600
  return `${hours ? hours.toString().padStart(2, '0') + ':' : ''}${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`
}

export function formatVodScore(likes) {
  if (!likes?.like) return 0

  return Math.round((likes.like / (likes.like + (likes.dislike || 0))) * 100)
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const currencyAdornment = {
  [Currencies.USD]: '$',
  [Currencies.PKN]: 'PKN'
}

export const computePlanSavings = (price: number, priceOneMonth: number, duration: number): number => {
  const supposedTotalPrice = priceOneMonth * duration
  const priceDifference = supposedTotalPrice - price
  return Math.round((priceDifference / supposedTotalPrice) * 100)
}