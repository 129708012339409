import React from 'react'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/client'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider } from '@emotion/react'

import { SearchProvider } from '../utils/search'
import { ConfigProvider } from '../context/config'
import { UserProvider } from '../context/user'
import { ProtectRoute } from '../context/protectRoute'
import { UploadProvider } from '../utils/upload'
import { ModalProvider } from '../utils/modal'
import { APIProvider } from '../utils/api'
import client from '../apolloClient'
import createEmotionCache from '../utils/emotionCache'

import 'video.js/dist/video-js.css'
import 'react-virtualized/styles.css'
import '../index.css'

const clientSideEmotionCache = createEmotionCache()

function MyApp({ Component, pageProps, emotionCache = clientSideEmotionCache }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ConfigProvider>
        <ModalProvider>
          <UserProvider>
            <ProtectRoute>
              <APIProvider>
                <UploadProvider>
                  <ApolloProvider client={client}>
                    <SearchProvider>
                      <CacheProvider value={emotionCache}>
                        <Head>
                          <title>Pokmi Admin</title>
                          <meta name='rating' content='adult' />
                          <meta
                            name='viewport'
                            content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
                          />
                          <link rel='icon' href='https://pokmi.com/favicon.ico' type='image/png' />
                        </Head>
                        <CssBaseline />
                        <Component {...pageProps} />
                      </CacheProvider>
                    </SearchProvider>
                  </ApolloProvider>
                </UploadProvider>
              </APIProvider>
            </ProtectRoute>
          </UserProvider>
        </ModalProvider>
      </ConfigProvider>
    </LocalizationProvider>
  )
}

export default MyApp
